import { useContext } from 'react';
import { ThemeContext } from '../../Context/ThemeContext/ThemeContext';
import ThemeChangeButton from '../ThemeChangeButton/ThemeChangeButton';

import './Navbar.css';

function Navbar() {
  const theme = useContext(ThemeContext).state;

  return (
    <div
      style={{
        color: theme.textColour,
        borderColor: theme.secondaryBackground,
      }}
      className="navbarContainer">
      <div className="navbar">
        <a className="navbarLeft" href="/" autoFocus>
          <img src="/logo.png" alt="my icon" className="navbarImage" />
          <span className="navbarImageText">Scarlette Fox</span>
        </a>
        <div className="navbarRight">
          <span className="navbarThemeButton">
            <ThemeChangeButton />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
