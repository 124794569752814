import { useContext } from 'react';
import { ThemeContext } from '../../Context/ThemeContext/ThemeContext';
import SunIcon from '../Icons/Sun';
import MoonIcon from '../Icons/Moon';

import './ThemeChangeButton.css';

function ThemeChangeButton() {
  const theme = useContext(ThemeContext);

  function changeThemeClick() {
    if (theme.state.mode === 'dark') {
      localStorage.setItem('theme', 'light');
      theme.dispatch({ type: 'light' });
    } else {
      localStorage.setItem('theme', 'dark');
      theme.dispatch({ type: 'dark' });
    }
  }
  return (
    <div>
      <button
        style={{ color: theme.state.textColour }}
        className="themeChangeButton"
        onClick={changeThemeClick}
        aria-label={`Change page theme`}>
        {theme.state.mode === 'light' ? (
          <SunIcon size={32} />
        ) : (
          <MoonIcon size={32} />
        )}
      </button>
    </div>
  );
}

export default ThemeChangeButton;
