import { createContext, useReducer } from 'react';
import { dark, light } from '../../Theme/Theme';

let initialState = null;

if (localStorage.getItem('theme') === 'light') {
  initialState = light;
} else {
  initialState = dark;
}

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'light':
      return light;
    case 'dark':
      return dark;
    default:
      return state;
  }
};

export function ThemeProvider(props) {
  const [state, dispatch] = useReducer(themeReducer, initialState);

  return (
    <ThemeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export const ThemeContext = createContext();
