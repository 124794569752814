export const light = {
  mode: 'light',
  background: '#e9ebf0',
  secondaryBackground: '#ebf0f7',
  textColour: '#0d0d0d',
  accentColour: '#6f12cc',
};

export const dark = {
  mode: 'dark',
  background: '#22272e',
  secondaryBackground: '#2d333b',
  textColour: '#cddceb',
  accentColour: '#6f12cc',
};
