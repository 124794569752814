import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { useContext } from 'react';
import { ThemeContext } from './Context/ThemeContext/ThemeContext';
import EmailIcon from './Components/Icons/Email';
import GithubIcon from './Components/Icons/Github';
import Instagram from './Components/Icons/Instagram';
import LinkedIn from './Components/Icons/LinkedIn';
import Twitter from './Components/Icons/Twitter';

function App() {
  const theme = useContext(ThemeContext).state;
  return (
    <div
      className="App"
      style={{
        color: theme.textColour,
        backgroundColor: theme.background,
      }}>
      <Navbar />
      <div className="mainContent">
        <div
          className="card"
          style={{
            backgroundColor: theme.secondaryBackground,
          }}>
          <section id="cardLeft">
            <img
              src="/logo.png"
              id="topImage"
              height="200"
              width="200"
              alt="My icon"
            />
            <h1 style={{ margin: 0 }}>Scarlette Fox</h1>
            <section id="cardLeftMiddle">
              Currently, I have no publicly available projects. When i do
              though, you will be able to view them here
            </section>
            <section id="cardLeftBottom">
              <section className="logoContainer">
                <a
                  href="mailto:scarlette@starnightfox.space"
                  className="socialLink">
                  <EmailIcon size={32} />
                </a>
              </section>
              <section className="logoContainer">
                <a
                  href="https://github.com/starnightfox"
                  className="socialLink">
                  <GithubIcon size={32} />
                </a>
              </section>
              <section className="logoContainer">
                <a
                  href="https://instagram.com/starnightfoxuwu"
                  className="socialLink">
                  <Instagram size={32} />
                </a>
              </section>
              <section className="logoContainer">
                <a
                  href="https://www.linkedin.com/in/starnightfox/"
                  className="socialLink">
                  <LinkedIn size={32} />
                </a>
              </section>
              <section className="logoContainer">
                <a
                  href="https://twitter.com/starnightfox"
                  className="socialLink">
                  <Twitter size={32} />
                </a>
              </section>
            </section>
          </section>
          <section id="cardRight">
            <img
              src="/logo.png"
              alt="my icon"
              height="200"
              width="200"
            />
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
